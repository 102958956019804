import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class AccountHelp extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Work Scheduler, Planner and Dashboard - Account help area."
            />
            <title>DayViewer Account Help</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>Account Help</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="My Account" key="1">
                      <p>
                        DayViewer requires your email address to create an
                        account, sign up in seconds and get started. Choose a
                        strong password with a mix of letters and numbers or non
                        alpha-numeric characters.
                      </p>
                      <p>
                        DayViewer can be used for free on the Starter Plan.
                        However to enjoy the full potential the system offers we
                        stongly recommend upgrading to the appropriate plan.
                      </p>
                      <p>
                        If you have forgotten your password, click the "Forgot
                        your password" link beneath the "Login" button.
                      </p>
                    </Panel>
                    <Panel header="Account Settings" key="2">
                      <p>
                        In the Side Menu, click Accounts - Then select Profile
                        to set up account related information.
                      </p>
                    </Panel>

                    <Panel header="Upgrading your Plan" key="3">
                      <p>
                        {" "}
                        If you have subscribed to a plan. You will be prompted
                        to add a credit / debit card{" "}
                      </p>
                      <p>
                        {" "}
                        After entering the details, and confirming, if all is
                        well with the payment method, your trial will begin{" "}
                      </p>
                      <p>
                        Unfortunately you cannot have more than one trial per
                        product plan, therefore we suggest you try everything
                        you need to maximum effect in the time limit of the free
                        trial. If you cancel and re-subscribe, the trial period
                        is forfeited, and you will be charged upon
                        re-subscription (this is to prevent fraudulent
                        behaviour).
                      </p>

                      <p>
                        The billing is recurring according to your selected
                        plan, if you have a coupon code, this must be used
                        during the checkout where available
                      </p>

                      <p>
                        Payments are in USD, your card company will convert to
                        the equivalent value local to your currency. Payments
                        are processed by Stripe Inc. DayViewer does not hold
                        card details in the database.
                      </p>

                      <p>
                        You can unsubscribe(cancel) your subscription anytime -
                        resubscribing will resume from where you left off (any
                        data entered during the subscription period will
                        remain). If you cancel within a subscription billing
                        period unfortunately we cannot partially refund for any
                        unused periods.
                      </p>
                    </Panel>
                    <Panel header="Membership &amp; Billing" key="4">
                      <p>
                        Within Accounts area, you should be able to tab between
                        your billing details (under billing) and upgrade and
                        downgrade membership / plan accordingly. Any changes
                        will be reflected in your next billing cycle.
                      </p>
                      <p>
                        Invoices are found in the Billing > Invoices section.
                        You may download PDF file version for your records.
                      </p>
                    </Panel>
                    <Panel header="Changing Account Details" key="5">
                      <p>
                        Within the Accounts ->Profile area, you can set some
                        details like Name, Locale, Currency and Country
                      </p>
                      <p> You can also change your password in this area.</p>
                      <p>
                        {" "}
                        You cannot change email address within DayViewer account
                        section, however you can request us to change this
                        should you wish to do so using the Contact Us form.
                      </p>
                    </Panel>
                    <Panel header="Closing Account" key="6">
                      <p>
                        If you find DayViewer is not for you, you can close &
                        delete your account from the Accounts-> Profile section.
                        Note that Deleting your account is non-recoverable - so
                        be absolutely sure you wish to do this.
                      </p>

                      <p>
                        Important, prior to closing your DayViewer account,
                        please ensure you have Unsubscribed from any
                        subscription based service you have with us, otherwise
                        you may still be charged.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default AccountHelp;
